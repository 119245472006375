@charset "UTF-8";
@import url("//hello.myfonts.net/count/343b81");
@font-face {
  font-family: "AvantGardeGothicITCW01Bk";
  src: url("../webfonts/343B81_0_0.eot");
  src: url("../webfonts/343B81_0_0.eot?#iefix") format("embedded-opentype"), url("../webfonts/343B81_0_0.woff2") format("woff2"), url("../webfonts/343B81_0_0.woff") format("woff"), url("../webfonts/343B81_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "AvantGardeGothicITCW02Md";
  src: url("../webfonts/343B81_1_0.eot");
  src: url("../webfonts/343B81_1_0.eot?#iefix") format("embedded-opentype"), url("../webfonts/343B81_1_0.woff2") format("woff2"), url("../webfonts/343B81_1_0.woff") format("woff"), url("../webfonts/343B81_1_0.ttf") format("truetype");
}
.purple {
  color: rgb(112, 108, 171);
}

.green {
  color: rgb(112, 195, 110);
}

.teal {
  color: rgb(46, 134, 193);
}

.peter-river-2 {
  color: rgb(40, 116, 166);
}

.peter-river-button {
  color: rgb(52, 152, 219);
}

.black {
  color: black;
}

.yellow {
  color: rgb(250, 255, 69);
}

.pink {
  color: rgb(239, 49, 125);
}

.red {
  color: #df497f;
}

.black-color {
  color: black;
}

/*
Pixels to ems function
*/
/*
Font size and line height mixin
*/
.big-padding {
  padding: 60px 0;
}

.med-padding {
  padding: 40px 0;
}

.small-padding {
  padding: 25px;
}

.end-margin {
  margin-bottom: 120px;
}

.small-padding-top {
  padding: 25px 0;
}

.big-pad-top {
  padding-top: 60px;
}

@media screen and (min-width: 768px) {
  .gutter-pad-left {
    padding-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .big-padding, .med-padding {
    padding: 20px 0;
  }
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: rgb(112, 108, 171);
  --pink: rgb(239, 49, 125);
  --red: #df497f;
  --orange: #fd7e14;
  --yellow: rgb(250, 255, 69);
  --green: rgb(112, 195, 110);
  --teal: #2E86C1;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: rgb(112, 195, 110);
  --info: #17a2b8;
  --warning: rgb(250, 255, 69);
  --danger: #df497f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgb(0, 86.1, 178.5);
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  margin-top: 20px;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(0, 98.4, 204) !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785) !important;
}

.bg-success {
  background-color: rgb(112, 195, 110) !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(76.8195121951, 179.6585365854, 74.3414634146) !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.bg-warning {
  background-color: rgb(250, 255, 69) !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(248.6290322581, 255, 18) !important;
}

.bg-danger {
  background-color: #df497f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: rgb(208.3644859813, 36.6355140187, 98.4579439252) !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(218.25, 223.5, 228.75) !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(29.1379310345, 32.5, 35.8620689655) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: rgb(112, 195, 110) !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: rgb(250, 255, 69) !important;
}

.border-danger {
  border-color: #df497f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.col-3-img {
  padding-bottom: 1rem;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(0, 86.1, 178.5) !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-success {
  color: rgb(112, 195, 110) !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(69.1073170732, 161.6219512195, 66.8780487805) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-warning {
  color: rgb(250, 255, 69) !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(240.8467741935, 247.5, 0) !important;
}

.text-danger {
  color: #df497f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(186.6775700935, 32.8224299065, 88.2102803738) !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

label {
  color: #191919;
  font-weight: normal;
}

.image-and-stat .gform_wrapper label {
  color: #585858;
}
.image-and-stat .gform_wrapper .gfield_label {
  font-size: 20px; /* 20px */
  font-size: 1.25rem; /* 20px */
  color: black;
}

input[type=text],
input[type=search],
input[type=password],
input[type=number],
input[type=email],
input[type=url],
input[type=tel],
input[type=file],
textarea,
select {
  padding: 12.75px;
  padding: 0.75rem;
  background: white;
  color: #495057;
  border: solid 1px #585858;
  border-bottom: solid 4px #585858;
  border-radius: 0;
  line-height: normal;
  display: block;
  width: 100%;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 15px 22px;
}
input[type=text]:focus,
input[type=search]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=tel]:focus,
input[type=file]:focus,
textarea:focus,
select:focus {
  border-color: #999;
  outline: none;
}

input[type=file]:hover {
  cursor: pointer;
}

input[type=search] {
  width: auto;
  display: inline-block;
}

select {
  height: 52px;
  padding: 0 22px;
}

select[multiple],
select[size] {
  height: auto;
}

input[type=checkbox],
input[type=radio] {
  width: auto;
  display: inline-block;
}

input[type=submit] {
  border: none;
  color: #ffffff;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.2307692308; /* 16px */
  letter-spacing: 2px;
  background: rgb(40, 116, 166);
}

input[type=submit],
input[type=reset],
input[type=button] {
  margin: 0;
}

input[type=checkbox]:focus,
input[type=radio]:focus,
input[type=submit]:focus,
input[type=reset]:focus,
input[type=button]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ginput_complex::after,
.clear-multi::after,
.ginput_full::after,
.gf_list_2col::after,
.gf_list_3col::after,
.gf_list_4col::after,
.gf_list_5col::after,
.gf_list_inline::after,
.gf_page_steps::after,
.ui-datepicker-header::after {
  clear: both;
  content: "";
  display: table;
}

.gform_wrapper form {
  margin-top: 30px;
  margin-top: 1.7647058824rem;
  margin-bottom: 30px;
  margin-bottom: 1.7647058824rem;
}

.gform_heading {
  margin-bottom: 25.5px;
  margin-bottom: 1.5rem;
  padding-bottom: 12.75px;
  padding-bottom: 0.75rem;
}
.gform_heading .gform_title {
  margin-bottom: 12.75px;
  margin-bottom: 0.75rem;
}

.gform_wrapper label,
.gform_wrapper .gfield_label {
  color: #cdcdcd;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 12px; /* 12px */
  font-size: 0.75rem; /* 12px */
  line-height: 1.25; /* 15px */
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: block;
}

.bg-dark-grey .gform_wrapper label {
  color: white;
}

.gsection {
  margin-bottom: 12.75px;
  margin-bottom: 0.75rem;
  padding-bottom: 12.75px;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #858585;
}
.gsection .gsection_title {
  margin-bottom: 12.75px;
  margin-bottom: 0.75rem;
}

.gfield_required {
  color: #f00;
  padding-left: 2px;
}

.ginput_complex label {
  font-weight: normal;
}

.gform_fields {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gfield {
  margin-bottom: 22px;
  margin-bottom: 1.2941176471rem;
  position: relative;
}

.gfield_description {
  font-size: 14px;
  font-size: 0.8235294118rem;
  font-style: italic;
}

.validation_message {
  font-style: normal;
  margin-top: 20px;
  color: #ca3c3c;
}

.gfield_checkbox,
.gfield_radio {
  list-style: none;
  margin: 0;
}
.gfield_checkbox input,
.gfield_radio input {
  margin-right: 4px;
  margin-right: 0.2352941176rem;
}

.gfield_radio input[type=text] {
  font-size: 12px;
  font-size: 0.7058823529rem;
  color: #585858;
  width: auto;
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  line-height: 1.25;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.ginput_complex .ginput_left {
  width: 49%;
  float: left;
  clear: left;
}
.ginput_complex .ginput_right {
  width: 49%;
  float: right;
  clear: right;
}

.name_prefix {
  width: 10%;
  float: left;
  margin-right: 1%;
}

.name_first {
  width: 49%;
  float: left;
  margin-right: 1%;
}

.name_last {
  width: 50%;
  float: left;
  margin-right: 0;
}

.name_suffix {
  width: 9%;
  float: right;
}

input.datepicker_with_icon {
  margin-right: 12.75px;
  margin-right: 0.75rem;
  display: inline-block;
  width: auto;
}

.gfield_date_month,
.gfield_date_dropdown_month {
  display: inline-block;
  width: auto;
  margin-right: 1%;
}

.gfield_date_day,
.gfield_date_dropdown_day {
  display: inline-block;
  width: auto;
  margin-right: 1%;
}

.gfield_date_year,
.gfield_date_dropdown_year {
  display: inline-block;
  width: auto;
}

.gfield_time_hour {
  float: left;
  margin-right: 1%;
}
.gfield_time_hour input {
  width: inherit;
  display: inline-block;
}
.gfield_time_hour label {
  display: block;
}

.gfield_time_minute {
  width: 30%;
  float: left;
  margin-right: 1%;
}

.gfield_time_ampm {
  width: 20%;
  float: left;
  margin-right: 1%;
}

.gfield,
.gform_footer {
  clear: both;
}

.gform_footer {
  margin: 20px 0 50px;
  text-align: center;
}

.gf_left_half {
  width: 49%;
  float: left;
  clear: left;
}

.gf_right_half {
  width: 49%;
  float: right;
  clear: right;
}

.gf_left_third {
  clear: none;
  width: 32.5%;
  float: left;
  margin-right: 1.25%;
}

.gf_middle_third {
  clear: none;
  width: 32.5%;
  float: left;
}

.gf_right_third {
  clear: none;
  width: 32.5%;
  float: right;
}

.gf_inline {
  width: auto;
  display: inline-block;
  margin-right: 1%;
  vertical-align: top;
}

.gf_list_2col li {
  width: 50%;
  float: left;
}

.gf_list_3col li {
  width: 33%;
  float: left;
}

.gf_list_4col li {
  width: 25%;
  float: left;
}

.gf_list_5col li {
  width: 20%;
  float: left;
}

.gf_list_inline li {
  display: inline-block;
  margin-right: 1%;
}

.gf_list_height_25 li {
  height: 25px;
}

.gf_list_height_50 li {
  height: 50px;
}

.gf_list_height_75 li {
  height: 75px;
}

.gf_list_height_100 li {
  height: 100px;
}

.gf_list_height_125 li {
  height: 125px;
}

.gf_list_height_150 li {
  height: 150px;
}

.gfield_list {
  margin: 0;
}

.gfield_list > tbody > tr:nth-child(odd) > td,
.gfield_list > tbody > tr:nth-child(odd) > th {
  background: none;
  line-height: normal;
  padding: 0;
  border: 0;
}

.gfield_list > thead > tr > th {
  border: 0;
}

.gf_scroll_text {
  padding: 12px;
  border: 1px solid #cecece;
  height: 180px;
  overflow: auto;
}
.gf_scroll_text p:last-of-type {
  margin-bottom: 0;
}

.gf_hide_ampm .gfield_time_ampm {
  display: none;
}

.gf_hide_charleft .charleft {
  display: none;
}

.gf_page_steps {
  margin-bottom: 12.75px;
  margin-bottom: 0.75rem;
  line-height: 1;
}

.gf_step {
  display: inline-block;
  opacity: 0.2;
  margin-right: 1%;
}
.gf_step:last-of-type {
  margin-right: 0;
}

.gf_step_active {
  opacity: 1;
}

.gf_step_number {
  font-weight: bold;
  font-size: 150%;
}

.gfield_error {
  padding: 12.75px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-left-color: #d9534f;
}
.gfield_error > label,
.gfield_error .validation_message {
  color: #ca3c3c;
}
.gfield_error input,
.gfield_error textarea,
.gfield_error select {
  border: none;
  background: white;
}
.gfield_error input:focus,
.gfield_error textarea:focus,
.gfield_error select:focus {
  border-color: rgb(165.9072580645, 45.0927419355, 45.0927419355);
}

.gform_validation_container {
  display: none;
  position: absolute;
  left: -9000px;
}

.ui-datepicker {
  background: #fff;
  border: 1px solid #aaa;
}

.ui-datepicker-prev {
  width: 20%;
  float: left;
}

.ui-datepicker-next {
  width: 20%;
  float: right;
}

.ui-datepicker-title {
  width: 60%;
  float: left;
}
.ui-datepicker-title select {
  width: auto;
  display: inline-block;
}

.gform_confirmation_message,
.validation_error {
  font-size: 22px;
}

.gform_confirmation_message {
  border: solid 4px green;
  padding: 30px;
  margin: 20px 0;
}

.gfield_description.validation_message {
  margin-top: 15px;
}

.validation_error {
  margin-bottom: 30px;
  color: #d9534f;
}

.gform_heading {
  margin-bottom: 0px;
}

.gform_footer {
  margin-bottom: 0;
}

.gfield_checkbox,
.gfield_radio {
  margin-top: 18px;
  padding-left: 0;
}
.gfield_checkbox > .gchoice,
.gfield_radio > .gchoice {
  display: inline-block;
  width: 100%;
}
.gfield_checkbox > .gchoice input,
.gfield_radio > .gchoice input {
  float: left;
}
.gfield_checkbox > .gchoice label,
.gfield_radio > .gchoice label {
  float: left;
  padding-left: 10px;
}
.gfield_checkbox > .gchoice:first-child,
.gfield_radio > .gchoice:first-child {
  margin-left: 0 !important;
}
.gfield_checkbox > .gchoice:before,
.gfield_radio > .gchoice:before {
  display: none;
}

.ginput_container_consent {
  padding-top: 10px;
}
.ginput_container_consent input {
  float: left;
}
.ginput_container_consent label {
  padding-left: 20px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-list {
  height: 100%;
}
.slick-track {
  height: 100%;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slider-overlay--full .slick-prev,
.slider-overlay--full .slick-next {
  display: none !important;
}

.slick-prev,
.slick-next {
  position: absolute;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  z-index: 99;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  border-bottom: solid 2px white;
  border-left: solid 2px white;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}

.slider-overlay--arrows .slick-prev {
  display: block;
  left: 40px;
  transform: translate(0, -50%) rotate(45deg);
}
.slider-overlay--arrows .slick-next {
  display: block;
  right: 40px;
  transform: translate(0, -50%) rotate(-135deg);
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 30px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: white;
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  background-color: rgb(40, 116, 166);
}
.slick-dots li.slick-active button {
  background-color: rgb(40, 116, 166);
}

.slider--publications .slick-dots {
  text-align: left;
  bottom: 30px;
}
.slider--publications .slick-dots li button:before {
  background-color: transparent;
  content: "";
}

.slider--feedback .slick-dots {
  text-align: center;
  bottom: 30px;
  left: 0;
}
.slider--feedback .slick-dots li button:before {
  background-color: transparent;
  content: "";
}
.slider--feedback .slick-dots button {
  background-color: white;
}

/* Slider */
.slick-list {
  height: 100%;
}
.slick-track {
  height: 100%;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slider-overlay--full .slick-prev,
.slider-overlay--full .slick-next {
  display: none !important;
}

.slick-prev,
.slick-next {
  position: absolute;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  z-index: 99;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  border-bottom: solid 2px white;
  border-left: solid 2px white;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}

.slider-overlay--arrows .slick-prev {
  display: block;
  left: 40px;
  transform: translate(0, -50%) rotate(45deg);
}
.slider-overlay--arrows .slick-next {
  display: block;
  right: 40px;
  transform: translate(0, -50%) rotate(-135deg);
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 30px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: white;
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  background-color: rgb(40, 116, 166);
}
.slick-dots li.slick-active button {
  background-color: rgb(40, 116, 166);
}

.slider--publications .slick-dots {
  text-align: left;
  bottom: 30px;
}
.slider--publications .slick-dots li button:before {
  background-color: transparent;
  content: "";
}

.slider--feedback .slick-dots {
  text-align: center;
  bottom: 30px;
  left: 0;
}
.slider--feedback .slick-dots li button:before {
  background-color: transparent;
  content: "";
}
.slider--feedback .slick-dots button {
  background-color: white;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  max-width: 90%;
  width: 80vw;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 940px;
  margin: 20px auto;
}
.white-popup h2 {
  color: #4A4A4A;
}
.white-popup h3 {
  color: rgb(46, 134, 193);
}

html {
  font-size: 16px;
  font-family: "AvantGardeGothicITCW01Bk", sans-serif;
}

.object-fit .box:not(.box--force) {
  background-color: transparent;
}
.object-fit img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.top-nav {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 50px 0 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}
.top-nav .language-switcher a {
  padding: 5px 10px;
}
.top-nav .secondary-menu {
  display: flex;
  justify-content: end;
  align-items: center;
}
.top-nav ul {
  position: relative;
}
.top-nav li {
  list-style: none;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 25px;
  padding-bottom: 30px;
}
.top-nav li a {
  display: inline-block;
  padding: 5px 0px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2.05px;
  color: white;
  font-family: "AvantGardeGothicITCW02Md", serif;
  position: relative;
}
.top-nav li:hover .sub-menu {
  opacity: 1;
  pointer-events: auto;
}
.top-nav li.current_page_ancestor > a:before {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% - 20px);
  left: 10px;
  bottom: 10px;
  background: rgb(40, 116, 166);
  bottom: -3px;
}
.top-nav .menu {
  padding-top: 15px;
  display: inline-block;
  padding-left: 0;
}
.top-nav .container-fluid, .top-nav .container-sm, .top-nav .container-md, .top-nav .container-lg, .top-nav .container-xl {
  padding: 0 60px;
}
.top-nav .sub-menu {
  opacity: 0;
  pointer-events: none;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 70px;
  padding: 50px;
  text-align: left;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.top-nav .sub-menu .sub-menu {
  transition: none;
}
.top-nav .sub-menu:after {
  position: absolute;
  height: 100%;
  width: 50%;
  right: 0;
  bottom: 0;
  background: url("../img/menu_bg_element.svg");
  background: contain;
  background-repeat: no-repeat;
  z-index: -1;
  transform: rotate(180deg);
}
.top-nav .sub-menu:after li {
  padding: 0;
  transition: all 0.4s ease-in-out;
}
.top-nav .sub-menu:after li a {
  color: #2e86c1 !important;
  line-height: 18px;
  transition: all 0.4s ease-in-out;
}
.top-nav .sub-menu:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  background: black;
  z-index: -5;
}
.top-nav .sub-menu li {
  display: block;
  padding-bottom: 0;
  padding: 0;
}
.top-nav .sub-menu .current_page_item a,
.top-nav .sub-menu a:hover {
  color: rgb(46, 134, 193);
}
.top-nav .menu-item-23 .sub-menu,
.top-nav .menu-item-3373 .sub-menu {
  text-align: right;
}
.top-nav__logo {
  width: 220px;
  height: 98px;
}
.top-nav .search-form {
  position: relative;
}
.top-nav .search-form input[type=text] {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  color: white;
  font-size: 12px; /* 12px */
  font-size: 0.75rem; /* 12px */
  line-height: 1.25; /* 15px */
  letter-spacing: 1.7px;
  text-transform: uppercase;
  border: none;
  width: 260px;
}
.top-nav .search-form .site-searchbar {
  position: relative;
  display: inline-block;
}
.top-nav .search-form input[type=submit] {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  background-image: url(../img/search-icon-white.svg);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .top-nav .secondary-menu .search-form {
    display: none;
  }
}

.hamburger-mobile {
  display: none;
}

.top-nav .wpml-ls-legacy-dropdown {
  width: auto;
}
.top-nav .wpml-ls-legacy-dropdown a {
  border: none;
  background-color: transparent;
}
.top-nav .wpml-ls-legacy-dropdown a:hover,
.top-nav .wpml-ls-legacy-dropdown a:focus,
.top-nav .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
  border-bottom: none;
  color: white;
  background-color: transparent;
}
.top-nav .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
  border-top: none;
}
.top-nav .wpml-ls-legacy-dropdown .wpml-ls-sub-menu li {
  margin-right: 21px;
}

.ig_action_bar.ig_air-mail.ig_container.ig_top {
  border: none;
}

.ig_action_bar.ig_hello.ig_hide .ig_close {
  box-shadow: none;
}

.ig_action_bar.ig_hello.ig_hide.ig_bottom .ig_close {
  margin-bottom: 0;
  border: none;
}

.ig_action_bar.ig_air-mail .ig_headline {
  display: none !important;
}

#icegram_messages_container .ig_action_bar.ig_air-mail .ig_data {
  max-width: 95%;
}

@media screen and (max-width: 600px) {
  .top-nav .menu-main-container li {
    padding: 0;
  }
  .top-nav .menu-main-container li a:after {
    position: absolute;
    content: url("../../assets/img/arrow-right.svg");
    padding-left: 1.5rem;
  }
  .top-nav .menu-main-container li:last-of-type a:after {
    content: "";
  }
  .top-nav .menu-main-container li .sub-menu li {
    padding: 0;
  }
  .top-nav .menu-main-container li .sub-menu li a:after {
    content: "";
  }
}
.banner {
  background: #cdcdcd;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  padding-bottom: 44%;
  color: white;
  font-family: "AvantGardeGothicITCW02Md", serif;
  margin-bottom: 60px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner__overlay {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  transform: scaleX(0.6);
  background: black;
  opacity: 0.7;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
}
.banner__overlay--full {
  transform: scaleX(1);
}
.banner__center-text {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 60px;
}
.banner__title {
  font-size: 60px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.banner--no-margin {
  margin-bottom: 0;
}
.banner.object-fit {
  padding-bottom: 0;
}
.banner img {
  position: absolute;
}
.banner--smaller-text .banner__title {
  font-size: 40px;
}
.banner--smaller-text .sub-title--small {
  font-size: 38px;
}
.banner .banner-searchbar {
  position: relative;
  display: inline-block;
}
.banner .banner-searchbar input[type=search] {
  background: #ffffff;
  padding: 17px 22px;
  color: #979797;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2; /* 18px */
  border: none;
  width: 260px;
}
.banner .banner-searchbar input[type=submit] {
  position: absolute;
  top: 14px;
  right: 10px;
  background: transparent;
  background-image: url(../img/search-icon-grey.svg);
  width: 21px;
  height: 21px;
  background-size: contain;
}

.single-blogpost .banner__overlay,
.single-post .banner__overlay {
  opacity: 0.4;
}

.sub-title {
  font-size: 24px;
}
.sub-title--small {
  font-size: 3.5rem;
}

.third-level-nav {
  background: #eeeeee;
  padding: 40px;
  text-align: center;
}
.third-level-nav ul {
  margin: 0;
}
.third-level-nav ul li {
  display: inline-block;
  padding: 0;
}
.third-level-nav ul a {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px; /* 14px */
  font-size: 0.875rem; /* 14px */
  line-height: 1.1428571429; /* 16px */
  letter-spacing: 2px;
  color: black;
  text-transform: uppercase;
  font-family: "AvantGardeGothicITCW02Md", serif;
}

@media screen and (max-width: 769px) {
  .banner__center-text {
    position: relative;
    width: 100%;
    padding-top: 170px;
    padding-bottom: 40px;
    transform: none;
  }
  .banner__center-text .banner__title {
    font-size: 3rem;
  }
}
.masonary {
  font-size: 0;
  position: relative;
  margin-top: 60px;
  margin-bottom: 120px;
}
.masonary__item {
  width: calc(33.333333% - 10px);
  height: 500px;
  display: inline-block;
  position: relative;
  background-position: center;
  background-size: cover;
  margin: 5px;
  vertical-align: top;
  transition: transform 0.3s ease-in-out;
}
.masonary__item-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  z-index: 0;
}
.masonary__item--large {
  width: calc(66.666666% - 10px);
}
.masonary__item--large .box__title {
  max-width: 730px;
}
.masonary__item > * {
  position: absolute;
  width: 100%;
  height: 100%;
}
.masonary__item:after {
  position: absolute;
  content: "";
  height: 65px;
  width: 90%;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 5%;
  z-index: -1;
  transition: transform 0.3s ease-in-out;
}
.masonary__item:hover:after {
  transform: scale(0.9);
}
.masonary__item.uncategorised .box {
  background: rgb(112, 195, 110);
}
.masonary__item.events .box, .masonary__item.events-fr .box {
  background: black;
}
.masonary__item.in-the-press .box, .masonary__item.press-release .box {
  background: rgb(112, 108, 171);
}
.masonary__item.downloads .box, .masonary__item.downloads-fr .box {
  background: rgb(112, 195, 110);
}
.masonary--small {
  margin-bottom: 30px;
}
.masonary--small .box__title {
  font-size: 30px; /* 30px */
  font-size: 1.875rem; /* 30px */
  line-height: 1.2666666667; /* 38px */
}

.masonary__item .selection-list li {
  transition: all 0.3s linear;
}

.masonary__item.downloads .box__content,
.masonary__item.downloads-fr .box__content {
  font-size: 16px; /* 16px */
  font-size: 1rem; /* 16px */
  line-height: 1.5; /* 24px */
  color: white;
}
.masonary__item.downloads .download-link,
.masonary__item.downloads-fr .download-link {
  display: block;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.2307692308; /* 16px */
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 12px 0;
  clear: both;
}
.masonary__item.downloads .download-link img,
.masonary__item.downloads-fr .download-link img {
  float: left;
  width: 24px;
  height: auto;
}
.masonary__item.downloads .download-link span,
.masonary__item.downloads-fr .download-link span {
  float: left;
  margin-top: 2px;
  margin-left: 10px;
}

.page-template-tpl-whats-new .masonary__item .button,
.archive .masonary__item .button {
  margin-top: 25px;
}

.button {
  color: #ffffff;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.2307692308; /* 16px */
  letter-spacing: 2px;
  text-align: center;
  display: inline-block;
  padding: 1.4375rem;
  text-transform: uppercase;
  font-family: "AvantGardeGothicITCW02Md", serif;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: solid 2px;
  border: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button:after {
  position: absolute;
  content: attr(title);
  padding: 1.4375rem;
  color: white;
  height: 100%;
  width: 100%;
  transform-origin: bottom right;
  transform: rotate(90deg);
  z-index: 99;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0.1s;
  -moz-transition: all 0.4s ease-in-out 0.1s;
  -ms-transition: all 0.4s ease-in-out 0.1s;
  -o-transition: all 0.4s ease-in-out 0.1s;
  transition: all 0.4s ease-in-out 0.1s;
}
.button:before {
  position: absolute;
  content: "";
  padding: 1.4375rem;
  color: white;
  height: 100%;
  width: 100%;
  transform-origin: bottom right;
  transform: rotate(90deg);
  z-index: 9;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.button--plain:before, .button--plain:after {
  display: none;
}
.button--purple {
  background: rgb(112, 108, 171);
  border-color: rgb(112, 108, 171);
}
.button--purple.button--ghost {
  color: rgb(112, 108, 171);
  background: transparent;
  border: solid 2px;
}
.button--purple.button--plain:hover {
  background: rgb(82.9714285714, 79.2, 138.6);
}
.button--purple:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--purple:after {
  background: rgb(82.9714285714, 79.2, 138.6);
  color: white;
}
.button--purple:before {
  background: rgb(92.6857142857, 88.4727272727, 154.8272727273);
  color: white;
}
.button--green {
  background: rgb(112, 195, 110);
  border-color: rgb(112, 195, 110);
}
.button--green.button--ghost {
  color: rgb(112, 195, 110);
  background: transparent;
  border: solid 2px;
}
.button--green.button--plain:hover {
  background: rgb(73.7346341463, 172.443902439, 71.356097561);
}
.button--green:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--green:after {
  background: rgb(73.7346341463, 172.443902439, 71.356097561);
  color: white;
}
.button--green:before {
  background: rgb(87.0970731707, 184.5512195122, 84.7487804878);
  color: white;
}
.button--teal {
  background: rgb(40, 116, 166);
  border-color: rgb(40, 116, 166);
}
.button--teal.button--ghost {
  color: rgb(40, 116, 166);
  background: transparent;
  border: solid 2px;
}
.button--teal.button--plain:hover {
  background: rgb(28.1165048544, 81.5378640777, 116.6834951456);
}
.button--teal:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--teal:after {
  background: rgb(28.1165048544, 81.5378640777, 116.6834951456);
  color: white;
}
.button--teal:before {
  background: rgb(33.067961165, 95.8970873786, 137.232038835);
  color: white;
}
.button--peter-river-2 {
  background: rgb(40, 116, 166);
  border-color: rgb(40, 116, 166);
}
.button--peter-river-2.button--ghost {
  color: rgb(40, 116, 166);
  background: transparent;
  border: solid 2px;
}
.button--peter-river-2.button--plain:hover {
  background: rgb(28.1165048544, 81.5378640777, 116.6834951456);
}
.button--peter-river-2:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--peter-river-2:after {
  background: rgb(28.1165048544, 81.5378640777, 116.6834951456);
  color: white;
}
.button--peter-river-2:before {
  background: rgb(33.067961165, 95.8970873786, 137.232038835);
  color: white;
}
.button--peter-river-button {
  background: rgb(52, 152, 219);
  border-color: rgb(52, 152, 219);
}
.button--peter-river-button.button--ghost {
  color: rgb(52, 152, 219);
  background: transparent;
  border: solid 2px;
}
.button--peter-river-button.button--plain:hover {
  background: rgb(31.6016736402, 119.3841004184, 178.1983263598);
}
.button--peter-river-button:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--peter-river-button:after {
  background: rgb(31.6016736402, 119.3841004184, 178.1983263598);
  color: white;
}
.button--peter-river-button:before {
  background: rgb(35.4426778243, 133.8945606695, 199.8573221757);
  color: white;
}
.button--black {
  background: black;
  border-color: black;
}
.button--black.button--ghost {
  color: black;
  background: transparent;
  border: solid 2px;
}
.button--black.button--plain:hover {
  background: black;
}
.button--black:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--black:after {
  background: black;
  color: white;
}
.button--black:before {
  background: black;
  color: white;
}
.button--yellow {
  background: rgb(250, 255, 69);
  border-color: rgb(250, 255, 69);
}
.button--yellow.button--ghost {
  color: rgb(250, 255, 69);
  background: transparent;
  border: solid 2px;
}
.button--yellow.button--plain:hover {
  background: rgb(248.3548387097, 255, 7.8);
}
.button--yellow:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--yellow:after {
  background: rgb(248.3548387097, 255, 7.8);
  color: white;
}
.button--yellow:before {
  background: rgb(249.0403225806, 255, 33.3);
  color: white;
}
.button--pink {
  background: rgb(239, 49, 125);
  border-color: rgb(239, 49, 125);
}
.button--pink.button--ghost {
  color: rgb(239, 49, 125);
  background: transparent;
  border: solid 2px;
}
.button--pink.button--plain:hover {
  background: rgb(210.4540540541, 16.3459459459, 93.9891891892);
}
.button--pink:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--pink:after {
  background: rgb(210.4540540541, 16.3459459459, 93.9891891892);
  color: white;
}
.button--pink:before {
  background: rgb(234.1162162162, 18.1837837838, 104.5567567568);
  color: white;
}
.button--red {
  background: #df497f;
  border-color: #df497f;
}
.button--red.button--ghost {
  color: #df497f;
  background: transparent;
  border: solid 2px;
}
.button--red.button--plain:hover {
  background: rgb(199.6897196262, 35.1102803738, 94.3588785047);
}
.button--red:hover {
  color: rgba(255, 255, 255, 0.5);
}
.button--red:after {
  background: rgb(199.6897196262, 35.1102803738, 94.3588785047);
  color: white;
}
.button--red:before {
  background: rgb(217.661682243, 42.638317757, 105.646728972);
  color: white;
}
.button:hover, .button:focus {
  outline: none;
  text-decoration: none;
}
.button:hover:after, .button:hover:before, .button:focus:after, .button:focus:before {
  transform: rotate(0);
}
.button--small {
  padding: 17px 25px;
}
.button--icon {
  padding-right: 45px;
}

.box:hover .button {
  outline: none;
  text-decoration: none;
}
.box:hover .button:after, .box:hover .button:before {
  transform: rotate(0);
}

.circle {
  padding: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: inline-block;
}
.circle--play {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
}
.circle--play:before {
  left: 0;
  position: absolute;
  content: "";
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: white;
  transform: scale(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.circle--play:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #2E86C1;
  top: 31px;
  left: 36px;
}
.circle--play:hover:before {
  transform: scale(1);
}

.video-popup:hover .circle:before {
  transform: scale(1);
}

.title {
  color: #000000;
  font-family: "AvantGardeGothicITCW02Md", serif;
  letter-spacing: -2px;
  font-size: 72px; /* 72px */
  font-size: 4.5rem; /* 72px */
  line-height: 1.25; /* 90px */
}

.heading, .call-out__heading {
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 12px; /* 12px */
  font-size: 0.75rem; /* 12px */
  line-height: 1.25; /* 15px */
  letter-spacing: 2px;
  text-transform: uppercase;
}

.intro {
  font-size: 28px; /* 28px */
  font-size: 1.75rem; /* 28px */
  line-height: 1.3571428571; /* 38px */
}

p,
ol,
ul {
  font-size: 20px; /* 20px */
  font-size: 1.25rem; /* 20px */
  line-height: 1.5; /* 30px */
}

h2 {
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 48px; /* 48px */
  font-size: 3rem; /* 48px */
  line-height: 1.0833333333; /* 52px */
  letter-spacing: -1px;
}

h3 {
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 32px; /* 32px */
  font-size: 2rem; /* 32px */
  line-height: 1.625; /* 52px */
  letter-spacing: -1px;
}

blockquote {
  max-width: 540px;
  margin: 50px auto;
  color: rgb(46, 134, 193);
  font-size: 28px; /* 28px */
  font-size: 1.75rem; /* 28px */
  line-height: 1.3571428571; /* 38px */
  text-align: center;
}
blockquote p {
  margin: 50px auto;
  color: rgb(46, 134, 193);
  font-size: 28px; /* 28px */
  font-size: 1.75rem; /* 28px */
  line-height: 1.3571428571; /* 38px */
}
blockquote cite {
  font-style: normal;
  display: block;
  margin-top: 20px;
  color: #9b9b9b;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2666666667; /* 19px */
  text-transform: uppercase;
  letter-spacing: 2px;
}

a {
  color: rgb(46, 134, 193);
  transition: all 0.3s linear;
}
a:hover {
  text-decoration: none;
}
a:focus {
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

img.alignleft {
  float: left;
  margin-right: 30px;
}

img.alignright {
  float: right;
  margin-left: 30px;
}

img.logo-divider {
  max-width: 90%;
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignleft {
  float: left;
  margin-right: 30px;
}

.alignright {
  float: right;
  margin-left: 30px;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

.call-out {
  position: relative;
  background-color: #f0f5f8;
  box-shadow: 28px 22px 104px -2px rgba(173, 173, 173, 0.76);
  padding: 30px 40px;
  margin-bottom: 60px;
}
.call-out a {
  color: #d33e88;
  font-weight: normal;
  padding-bottom: 1px;
  border-bottom: 1px solid #d33e88;
}
.call-out__heading {
  color: rgb(112, 108, 171);
  margin-bottom: 15px;
}

.floating-header .date-time-wrap {
  display: inline-block;
  vertical-align: middle;
}
.floating-header .date-time-wrap .heading, .floating-header .date-time-wrap .call-out__heading {
  display: block;
  margin-bottom: 7px;
}
.floating-header .date-time-wrap .date {
  margin: 5px 0;
  margin-left: 0px;
}

input:focus {
  outline: none;
}

.gfield_error {
  padding: 0 !important;
  border: none !important;
  border-left-width: 0 !important;
  border-left-color: transparent !important;
}

.gfield_description.validation_message {
  margin-top: 5px !important;
}

.comments-section label {
  margin-top: 10px;
  display: block;
}

body .ig_action_bar.ig_hello.ig_container {
  border: none;
  box-shadow: none;
}
body .ig_action_bar.ig_hello.ig_container a {
  color: black;
  font-weight: bold;
}

body .ig_action_bar.ig_hello .ig_button, .ig_action_bar.ig_hello input[type=submit], .ig_action_bar.ig_hello input[type=button] {
  border-radius: 0px !important;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  line-height: 1.23077;
  font-family: "AvantGardeGothicITCW02Md", serif;
}

.gform_wrapper label {
  color: #3e3e3e;
}

.contact-form .gform_wrapper label,
.contact-form .gform_wrapper legend {
  color: #CDCDCD;
}

.contact-form .gform_required_legend {
  display: none;
}

.contact-form input[type=text], .contact-form input[type=search], .contact-form input[type=password], .contact-form input[type=number], .contact-form input[type=email], .contact-form input[type=url], .contact-form input[type=tel], .contact-form input[type=file], .contact-form textarea, .contact-form select {
  border: none;
}

.gptos_terms_container + style + .ginput_container .gfield_checkbox > .gchoice label, .gptos_terms_container + style + .ginput_container .gfield_checkbox > .gchoice input {
  vertical-align: middle;
  float: none;
  display: inline-block;
}
.gptos_terms_container + style + .ginput_container .gfield_checkbox {
  text-align: center;
}
.gptos_terms_container + style + .ginput_container .gchoice {
  display: inline-block;
  width: auto;
  padding: 15px 35px 18px 35px;
  border: solid 2px rgb(52, 152, 219);
  margin-bottom: 20px;
}
.gptos_terms_container + style + .ginput_container .gchoice label {
  color: rgb(46, 134, 193);
  margin-bottom: 0;
}
.gptos_terms_container + style + .ginput_container input[type=checkbox] {
  -webkit-appearance: none;
  border-radius: 2px;
  background-color: #fff;
  border: 2px solid #c1c1c1;
  font-size: 1px;
  height: 24px;
  width: 24px;
}
.gptos_terms_container + style + .ginput_container input[type=checkbox]:focus {
  outline: none;
}
.gptos_terms_container + style + .ginput_container input[type=checkbox]:checked {
  background: rgb(40, 116, 166);
}

#CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialog #CybotCookiebotDialogBodyContentControls, #CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
  font-family: "AvantGardeGothicITCW01Bk", sans-serif !important;
}
#CybotCookiebotDialog a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, #CybotCookiebotDialog a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, #CybotCookiebotDialog a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  padding: 10px;
  display: inline-block !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  display: block;
  text-align: center;
}
#CybotCookiebotDialog #CybotCookiebotDialogBody, #CybotCookiebotDialog #CybotCookiebotDialogDetailBody {
  width: 900px;
  max-width: 95%;
}

.help-footer {
  background: rgb(40, 116, 166);
  padding: 60px 0 60px;
  text-align: center;
  color: white;
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.help-footer .box__title {
  margin-bottom: 40px;
}

.icon-link {
  padding: 0 20px;
  transition: 0.3s linear;
  color: white;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2666666667; /* 19px */
  letter-spacing: 2px;
}
.icon-link img {
  margin: auto;
  margin-bottom: 15px;
  display: block;
  transition: 0.3s ease-in-out;
  transform: rotateY(0deg);
}
.icon-link:hover {
  color: rgba(255, 255, 255, 0.8);
}
.icon-link:hover img {
  transform: rotateY(360deg);
}

.icon-holder {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: solid 2px white;
  margin: auto;
  margin-bottom: 15px;
}

.col-7 {
  text-align: center;
  font-size: 0;
}
.col-7__col {
  width: 14.285714%;
  display: inline-block;
  vertical-align: top;
}
.col-7--middle .col-7__col {
  vertical-align: middle;
}

.site-footer {
  background: black;
  color: white;
  padding-top: 60px;
}
.site-footer__title {
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.6666666667; /* 40px */
  font-family: "AvantGardeGothicITCW02Md", serif;
  margin-bottom: 12px;
}
.site-footer ul {
  padding: 0;
  margin: 0;
}
.site-footer ul li {
  display: inline-block;
  width: 48%;
  vertical-align: middle;
  list-style: none;
  text-transform: uppercase;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.3846153846; /* 18px */
  letter-spacing: 1px;
  padding-bottom: 6px;
}
.site-footer ul a {
  color: white;
}
.site-footer ul a:hover {
  color: rgb(46, 134, 193);
}
.site-footer address {
  font-size: 19px; /* 19px */
  font-size: 1.1875rem; /* 19px */
  line-height: 1.5789473684; /* 30px */
}
.site-footer .button.button--teal:after {
  padding-bottom: 30px;
}
.site-footer input[type=search] {
  background: #383737;
  padding: 17px 22px;
  color: white;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2; /* 18px */
  border: none;
  width: 260px;
}
.site-footer .site-searchbar {
  position: relative;
  display: inline-block;
}
.site-footer input[type=submit] {
  position: absolute;
  top: 14px;
  right: 10px;
  background: transparent;
  background-image: url(../img/search-icon-white.svg);
  width: 21px;
  height: 21px;
  background-size: contain;
  background-repeat: no-repeat;
}

.social-media-links {
  margin-top: 30px;
  margin-bottom: 32px;
}
.social-media-links a {
  text-align: center;
}
.social-media-links .social-media {
  background-color: rgb(40, 116, 166);
  color: white;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: inline-block;
  padding: 8px;
  margin: 0 3px;
}
.social-media-links .twitter {
  background-color: #00b6f1;
}
.social-media-links .twitter:hover {
  background-color: rgb(0, 143.4854771784, 190);
}
.social-media-links .facebook {
  background-color: #3b5998;
  padding: 8px 12px 8px 8px;
}
.social-media-links .facebook:hover {
  background-color: rgb(44.7393364929, 67.4881516588, 115.2606635071);
}
.social-media-links .linkedin {
  background-color: #007bb6;
  padding: 8px 10px 8px 8px;
}
.social-media-links .linkedin:hover {
  background-color: rgb(0, 88.532967033, 131);
}
.social-media-links .youtube {
  background-color: #ff0000;
  padding: 8px 9px 8px 8px;
}
.social-media-links .youtube:hover {
  background-color: #cc0000;
}

.copyright {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  margin-top: 70px;
}
.copyright span {
  padding-right: 10px;
}
.copyright a {
  color: white;
}
.copyright a:hover {
  color: rgb(46, 134, 193);
}

.instagram-block {
  padding: 20px;
  background-color: #eff3f6;
}
.instagram-block__image {
  width: 25%;
  padding: 10px;
}
.instagram-block__header {
  padding: 10px 10px 20px 10px;
}
.instagram-block h3 {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  letter-spacing: 1.5px;
  margin: 0;
  vertical-align: middle;
  line-height: 18px;
  margin-left: 9px;
}
.instagram-block i {
  font-size: 33px;
  vertical-align: middle;
}
.instagram-block a {
  color: rgb(112, 108, 171);
}

.slider-overlay {
  overflow: hidden;
}

.slick-initialized .slick-slide.slide--full {
  height: 100%;
  width: 100%;
  display: table;
}
.slick-initialized .slick-slide.slide--full > div {
  display: table-cell;
  vertical-align: middle;
}

.slide {
  color: white;
}
.slide a {
  color: white;
}
.slide a:hover {
  color: white;
}
.slide__bg {
  min-height: 520px;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.slide__title {
  font-size: 40px; /* 40px */
  font-size: 2.5rem; /* 40px */
  line-height: 1; /* 40px */
  letter-spacing: -1;
  font-family: "AvantGardeGothicITCW02Md", serif;
  margin-bottom: 30px;
}
.slide p {
  color: white;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.3333333333; /* 32px */
}

.box__stat-slider {
  height: 100%;
  margin-bottom: 0;
}

.slider-overlay {
  position: relative;
  max-height: 520px;
}
.slider-overlay .slide {
  height: 520px;
}
.slider-overlay--full {
  max-height: none;
}
.slider-overlay--full .slide {
  height: 100vh;
}
.slider-overlay--full .slide__title {
  font-size: 90px; /* 90px */
  font-size: 5.625rem; /* 90px */
  line-height: 1.0666666667; /* 96px */
  line-height: 1.1;
  letter-spacing: -3px;
}
.slider-overlay--full .slick-dots {
  bottom: 65px;
  text-align: right;
  padding: 0 45px;
}
.slider-overlay__content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}
.slider-overlay__content .slide {
  transition: all 0.5s ease-in-out 0s !important;
  -ms-transform: translateX(-100px) rotate(9deg); /* IE 9 */
  -webkit-transform: translateX(-100px) rotate(9deg); /* Safari */
  transform: translateX(-100px) rotate(9deg); /* Safari */
  opacity: 0;
}
.slider-overlay__content .slide.slick-current {
  transition: all 0.6s ease-in-out 1.7s !important;
  -ms-transform: translateX(0) rotate(0); /* IE 9 */
  -webkit-transform: translateX(0) rotate(0); /* Safari */
  transform: translateX(0) rotate(0); /* Safari */
}
.slider-overlay__overlay {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  transform: scaleX(0.6);
  background: black;
  opacity: 0.6;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.slider-overlay__overlay--cover {
  opacity: 1;
  transform: none;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.slider-overlay__overlay--off-right {
  transform: translateX(100%);
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.slider-overlay__overlay--off-left {
  transform: scaleX(0.6) translateX(-100%);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.slider-overlay__overlay--no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.slider-overlay__down {
  position: absolute;
  color: rgb(46, 134, 193);
  bottom: 55px;
  left: 100px;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 16px; /* 16px */
  font-size: 1rem; /* 16px */
  line-height: 1.25; /* 20px */
  letter-spacing: 2px;
  cursor: pointer;
  z-index: 999;
}
.slider-overlay__down img {
  margin-left: 10px;
}

.page-template-tpl-home .slider-overlay__down {
  color: white;
}

.slick-dots li button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 3px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button::before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  content: "";
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.slick-dots li.slick-active button::before,
.slick-dots li:hover button::before {
  background-color: rgb(40, 116, 166);
}

.box--peter-river-2 .slick-dots li button::before {
  background-color: transparent;
  border: 1px solid white;
}
.box--peter-river-2 .slick-dots li.slick-active button::before,
.box--peter-river-2 .slick-dots li:hover button::before {
  background-color: white;
}

@media screen and (max-width: 900px) {
  .slider-overlay__overlay {
    transform: none;
  }
}
section.related-strip {
  margin-top: 40px;
  margin-bottom: 60px;
}
section.related-strip .box__title {
  margin-bottom: 30px;
}
section.image-and-stat {
  margin-bottom: 70px;
}
section.video-strip {
  margin-bottom: 70px;
}
section.accordions {
  margin-bottom: 80px;
}
section.slider-overlay {
  margin-bottom: 80px;
}
section.q-and-a {
  margin-bottom: 120px;
}
section.contact-form {
  background: black;
  padding: 95px 0;
  color: white;
  margin-top: 40px;
}
section.no-margin {
  margin: 0;
}

.video-strip {
  position: relative;
}
.video-strip img {
  width: 100%;
}
.video-strip .circle {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.search-result {
  margin-bottom: 40px;
}
.search-result h3 {
  font-size: 32px; /* 32px */
  font-size: 2rem; /* 32px */
  line-height: 1.1875; /* 38px */
}

.accordion {
  border-bottom: 2px solid #cdcdcd;
}
.accordion__heading {
  color: #4a4a4a;
  font-size: 32px; /* 32px */
  font-size: 2rem; /* 32px */
  line-height: 1.625; /* 52px */
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: "AvantGardeGothicITCW02Md", serif;
  letter-spacing: -1px;
  position: relative;
  cursor: pointer;
}
.accordion__heading:after, .accordion__heading:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgb(40, 116, 166);
}
.accordion__heading:before {
  height: 4px;
  width: 16px;
  top: 23px;
}
.accordion__heading:after {
  width: 4px;
  height: 16px;
  top: 17px;
  transition: transform 0.3s ease-in-out;
  right: 6px;
}
.accordion__content {
  display: none;
  margin-bottom: 50px;
}
.accordion.is-active .accordion__heading:after {
  transform: scale(1, 0.2);
}
.accordion--large .accordion__heading {
  color: rgb(46, 134, 193);
  font-size: 40px; /* 40px */
  font-size: 2.5rem; /* 40px */
  line-height: 1.3; /* 52px */
}

.accordions__title {
  font-size: 48px; /* 48px */
  font-size: 3rem; /* 48px */
  line-height: 1.0833333333; /* 52px */
  letter-spacing: -1px;
  margin-bottom: 30px;
  font-family: "AvantGardeGothicITCW02Md", serif;
}

.categories {
  margin: 50px 0 0;
  text-align: center;
}
.categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.categories li {
  display: inline-block;
  color: #9b9b9b;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2666666667; /* 19px */
  letter-spacing: 2px;
  padding: 0 15px;
  text-transform: uppercase;
}
.categories li a {
  color: #a3a3a3;
}
.categories li:hover a {
  color: rgb(46, 134, 193);
}
.categories li.current_page_item a {
  color: rgb(46, 134, 193);
}

div.navigation {
  margin: 50px 0;
}
div.navigation ul.page-numbers {
  padding: 4px 8px;
  color: black;
  text-align: center;
  display: inline-block;
}
div.navigation ul.page-numbers span {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}
div.navigation ul.page-numbers span.dots {
  font-size: 1rem;
}
div.navigation a.page-numbers {
  font-weight: 300;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s linear;
}
div.navigation a.page-numbers:hover {
  color: rgb(46, 134, 193);
}
div.navigation .page-numbers li {
  display: inline-block;
  min-width: 50px;
  height: 50px;
  margin: 0;
  float: left;
  vertical-align: bottom;
}
div.navigation .page-numbers li > * {
  line-height: 50px;
  border: 1px solid #BDBDBD;
  border-right: none;
  color: black;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 1rem;
}
div.navigation .page-numbers li > *.current {
  color: rgb(46, 134, 193);
}
div.navigation .page-numbers li:first-child > * {
  border-radius: 4px 0 0 4px;
}
div.navigation .page-numbers li:last-child > * {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #BDBDBD;
}
div.navigation .current {
  color: rgb(46, 134, 193);
}
div.navigation .next.page-numbers {
  display: inline-block;
  position: relative;
  padding: 0 30px 0 15px;
}
div.navigation .next.page-numbers:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 20px;
  height: 10px;
  width: 10px;
  border-bottom: 3px solid #191919;
  border-left: 3px solid #191919;
  border-radius: 2px;
  -ms-transform: rotate(-135deg); /* IE 9 */
  -webkit-transform: rotate(-135deg); /* Safari */
  transform: rotate(-135deg); /* Safari */
}
div.navigation .prev.page-numbers {
  display: inline-block;
  position: relative;
  padding: 0 15px 0 30px;
}
div.navigation .prev.page-numbers:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 1.25em;
  height: 10px;
  width: 10px;
  border-bottom: 3px solid #191919;
  border-left: 3px solid #191919;
  border-radius: 2px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari */
  transform: rotate(45deg); /* Safari */
}

.box {
  background: rgb(40, 116, 166);
  background-size: cover;
  background-position: center;
  padding: 50px;
  height: 100%;
  color: white;
}
.box__title {
  font-size: 40px; /* 40px */
  font-size: 2.5rem; /* 40px */
  line-height: 1.15; /* 46px */
  font-family: "AvantGardeGothicITCW02Md", serif;
  letter-spacing: -1px;
  margin-bottom: 10px;
}
.box__meta {
  margin-bottom: 16px;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.2307692308; /* 16px */
  letter-spacing: 2px;
  text-transform: uppercase;
}
.box__meta .date {
  opacity: 1;
}
.box p {
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.3333333333; /* 32px */
}
.box__bottom {
  position: absolute;
  bottom: 50px;
  left: 50px;
  right: 50px;
}
.box__bottom--lower {
  bottom: 50px;
}
.box__bottom .box__title {
  margin-bottom: 0;
}
.box--bg {
  position: relative;
}
.box--bg:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  transition: 0.3s linear;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(30%, rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.6)), color-stop(100%, rgba(0, 0, 0, 0.6)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#000000", GradientType=0 );
}
.box__video-contain {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.box__video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  left: 0;
  bottom: 0;
  max-width: 894px;
}
.box:hover .box--bg {
  background: rgba(0, 0, 0, 0.4);
}
.box--purple {
  background: rgb(112, 108, 171);
}
.box--green {
  background: rgb(112, 195, 110);
}
.box--teal {
  background: rgb(40, 116, 166);
}
.box--peter-river-2 {
  background: rgb(40, 116, 166);
}
.box--peter-river-button {
  background: rgb(52, 152, 219);
}
.box--black {
  background: black;
}
.box--yellow {
  background: rgb(250, 255, 69);
}
.box--pink {
  background: rgb(239, 49, 125);
}
.box--red {
  background: #df497f;
}

.selection-list {
  font-size: 36px; /* 36px */
  font-size: 2.25rem; /* 36px */
  line-height: 1.2222222222; /* 44px */
  font-family: "AvantGardeGothicITCW02Md", serif;
  list-style: none;
  padding: 0;
  margin: 0;
}
.selection-list li {
  opacity: 0.4;
}
.selection-list li.active, .selection-list li:hover {
  opacity: 1;
  color: white;
}
.selection-list a {
  color: white;
}
.selection-list a:hover {
  opacity: 1;
  color: white;
}

.stat {
  text-align: center;
  margin-bottom: 40px;
}
.stat__icon {
  margin-bottom: 10px;
}
.stat__number {
  font-size: 150px; /* 150px */
  font-size: 9.375rem; /* 150px */
  line-height: 1.24; /* 186px */
  letter-spacing: -4px;
  font-family: "AvantGardeGothicITCW02Md", serif;
  color: rgb(46, 134, 193);
}
.stat__description {
  font-size: 22px; /* 22px */
  font-size: 1.375rem; /* 22px */
  line-height: 1.2727272727; /* 28px */
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.stat--purple .stat__number {
  color: rgb(112, 108, 171);
}
.stat--green .stat__number {
  color: rgb(112, 195, 110);
}
.stat--teal .stat__number {
  color: rgb(40, 116, 166);
}
.stat--peter-river-2 .stat__number {
  color: rgb(40, 116, 166);
}
.stat--peter-river-button .stat__number {
  color: rgb(52, 152, 219);
}
.stat--black .stat__number {
  color: black;
}
.stat--yellow .stat__number {
  color: rgb(250, 255, 69);
}
.stat--pink .stat__number {
  color: rgb(239, 49, 125);
}
.stat--red .stat__number {
  color: #df497f;
}
.stat--inline {
  display: inline-block;
  margin: 20px;
}
.stat--icon-inline-left .stat__icon {
  float: left;
}
.stat--icon-inline-right .stat__icon {
  float: right;
}

.stat--green.stat--icon-inline-left .stat__number {
  line-height: 1;
}

.keyfacts-row-2 {
  margin-top: 90px;
  margin-bottom: 60px;
}
.keyfacts-row-2 > div:nth-child(2) {
  margin-top: 220px;
}

.teaser {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.teaser__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}
.teaser__content {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%) scale(1);
  color: #ffffff;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.3333333333; /* 32px */
  padding: 0 20px;
  transform-style: preserve-3d;
  transition: all 0.3s ease-in-out;
}
.teaser.object-fit {
  padding-bottom: 0;
  min-height: 280px;
  max-height: 360px;
  height: 25vw;
}
.teaser--rectangle {
  padding-bottom: 50%;
}
.teaser--rectangle.object-fit {
  padding-bottom: 0;
  min-height: 220px;
  max-height: 280px;
  height: 20vw;
}

.teaser:hover .teaser__overlay {
  background: rgba(0, 0, 0, 0.75);
}
.teaser:hover .teaser__content {
  transform: translate(0, -50%) scale(1.1);
}

.mix-locations {
  margin-top: 35px;
}

.location-teaser {
  height: 100%;
}
.location-teaser__image {
  height: 0;
  padding-bottom: 45%;
  background: #dedede;
  background-size: cover;
  background-position: center;
}
.location-teaser__content {
  padding: 30px 40px 40px 40px;
  border: 1px solid #cdcdcd;
  height: 60%;
}
.location-teaser__city {
  color: #1d1e2a;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 30px; /* 30px */
  font-size: 1.875rem; /* 30px */
  line-height: 1.6666666667; /* 50px */
}
.location-teaser__country {
  font-size: 16px; /* 16px */
  font-size: 1rem; /* 16px */
  line-height: 1.125; /* 18px */
  font-family: "AvantGardeGothicITCW02Md", serif;
  color: black;
  margin-bottom: 15px;
}
.location-teaser__address, .location-teaser__contact {
  color: #2d2f49;
  font-size: 16px; /* 16px */
  font-size: 1rem; /* 16px */
  line-height: 1.5; /* 24px */
  margin-bottom: 10px;
}
.location-teaser .heading, .location-teaser .call-out__heading {
  color: #9b9b9b;
  padding-right: 8px;
  vertical-align: middle;
}

.staff-teaser {
  padding: 40px;
  border: 1px solid #cdcdcd;
  margin-bottom: 30px;
}
.staff-teaser__social {
  margin-top: 30px;
  text-align: right;
}
.staff-teaser__social img {
  height: 32px;
  width: 32px;
  margin-left: 10px;
}
.staff-teaser__right {
  float: right;
}
.staff-teaser__profile {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #cdcdcd;
  background-size: cover;
  background-position: center;
}
.staff-teaser__name {
  color: #1d1e2a;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.5833333333; /* 38px */
}
.staff-teaser__job {
  color: #000000;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 16px; /* 16px */
  font-size: 1rem; /* 16px */
  line-height: 1.75; /* 28px */
  margin-bottom: 10px;
}
.staff-teaser__contact {
  color: #2d2f49;
  font-size: 18px; /* 18px */
  font-size: 1.125rem; /* 18px */
  line-height: 1.4444444444; /* 26px */
  margin-bottom: 10px;
}
.staff-teaser__contact .heading, .staff-teaser__contact .call-out__heading {
  color: #9b9b9b;
  padding-right: 8px;
  vertical-align: middle;
}

.service-teasers .col-md-6 {
  margin-bottom: 40px;
}

.mix-vacancies {
  margin-top: 40px;
  margin-bottom: 40px;
}

.vacancy-item {
  padding: 45px 40px;
  border: solid 1px #cdcdcd;
  margin-bottom: 30px;
  height: calc(100% - 30px);
}
.vacancy-item h3 {
  color: #1d1e2a;
  margin-bottom: 16px;
  font-size: 32px; /* 32px */
  font-size: 2rem; /* 32px */
  line-height: 1.1875; /* 38px */
  margin-top: 0;
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.vacancy-item p {
  font-size: 18px; /* 18px */
  font-size: 1.125rem; /* 18px */
  line-height: 1.5555555556; /* 28px */
  margin-bottom: 15px;
}
.vacancy-item__detail {
  color: #2d2f49;
  font-size: 18px; /* 18px */
  font-size: 1.125rem; /* 18px */
  line-height: 1.7777777778; /* 32px */
}
.vacancy-item__detail > span {
  color: #9b9b9b;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 2.0769230769; /* 27px */
  letter-spacing: 1px;
  padding-right: 15px;
  text-transform: uppercase;
}
.vacancy-item .button {
  margin-top: 30px;
}

.filters {
  text-align: center;
  margin-top: 20px;
}
.filters .mix-filter {
  text-align: left;
  margin: 10px;
  width: 300px;
  display: inline-block;
}
.filters select {
  border: 1px solid #cdcdcd;
  border-radius: 0;
  -webkit-appearance: none;
}
.filters .heading, .filters .call-out__heading {
  margin-bottom: 10px;
}

.mix {
  display: none;
}

.floating-header {
  transform: translate(0, -140px);
  margin-bottom: -80px;
}
.floating-header .row {
  padding: 55px 60px;
  background: white;
  box-shadow: 2px -2px 14px 0 rgba(0, 0, 0, 0.5);
}
.floating-header .overview p {
  font-size: 28px; /* 28px */
  font-size: 1.75rem; /* 28px */
  line-height: 1.3571428571; /* 38px */
}
.floating-header .date {
  font-size: 16px;
  margin-left: 10px;
  color: #aeaeae;
}

.single-post .floating-header {
  transform: translate(0, -100px);
  margin-bottom: -50px;
}
.single-post .floating-header .row {
  padding: 25px 20px;
}

.speech-bubble {
  border-radius: 12px;
  border: solid 2px #2E86C1;
  padding: 35px 30px;
  min-height: 200px;
  max-width: 310px;
  text-align: center;
  font-size: 20px; /* 20px */
  font-size: 1.25rem; /* 20px */
  line-height: 1.5; /* 30px */
  position: relative;
  cursor: pointer;
  transition: 0.3s linear;
  background: rgb(40, 116, 166);
  transition: all 0.4s ease-in;
  color: white;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
}
.speech-bubble strong {
  font-size: 25px;
}
.speech-bubble:before, .speech-bubble:after {
  content: "";
  position: absolute;
}
.speech-bubble:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 42px 33px 0;
  border-color: transparent #2E86C1 transparent transparent;
  bottom: -33px;
  right: 23px;
}

.q-and-a {
  position: relative;
  padding-bottom: 40px;
}
.q-and-a__left-col .speech-bubble:nth-child(1) {
  margin-top: 140px;
}
.q-and-a__left-col .speech-bubble:nth-child(2) {
  margin-top: 90px;
  margin-left: 25px;
}
.q-and-a__center-col .speech-bubble:nth-child(3) {
  margin-left: 45px;
}
.q-and-a__right-col .speech-bubble:nth-child(1) {
  margin-top: 50px;
}
.q-and-a__right-col .speech-bubble:nth-child(2) {
  margin-left: 45px;
  margin-top: 105px;
}
.q-and-a .central-question {
  margin: 85px 0;
  font-family: "AvantGardeGothicITCW02Md", serif;
  color: rgb(46, 134, 193);
  font-size: 40px; /* 40px */
  font-size: 2.5rem; /* 40px */
  line-height: 1.1; /* 44px */
}
.q-and-a .central-question__heading {
  color: #4a4a4a;
  font-size: 15px; /* 15px */
  font-size: 0.9375rem; /* 15px */
  line-height: 1.2666666667; /* 19px */
  letter-spacing: 2px;
  margin-bottom: 30px;
  text-transform: uppercase;
  margin-top: 25px;
}
.q-and-a .central-question__heading.reset {
  color: rgb(46, 134, 193);
}
.q-and-a .central-question__heading.reset img {
  margin-right: 8px;
}
.q-and-a__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 90%;
  background: rgb(255, 255, 255);
  display: none;
  margin: 0 auto;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.5);
}
.q-and-a__overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 999;
  cursor: pointer;
  font-size: 42px; /* 42px */
  font-size: 2.625rem; /* 42px */
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.q-and-a .overlay__center {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}
.q-and-a .overlay__center > div {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.q-and-a .reset {
  cursor: pointer;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.2307692308; /* 16px */
  margin-top: 50px;
}
.q-and-a .demo {
  margin-top: 35px;
  color: #000000;
  font-size: 20px; /* 20px */
  font-size: 1.25rem; /* 20px */
  line-height: 1.5; /* 30px */
}
.q-and-a .demos img {
  height: 64px;
  margin: 5px;
}
.q-and-a .overlay_button .button {
  margin-top: 30px;
  margin-bottom: -20px;
}
.q-and-a .speech-bubble:hover {
  transform: scale(1.2);
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.5);
}

.central-question--mobile {
  display: none;
}

.leadership-item {
  margin-bottom: 50px;
}
.leadership-item__image {
  background: #cdcdcd;
  width: 100%;
  aspect-ratio: 4/5;
  background-size: cover;
  background-position: center;
  margin-bottom: 13px;
}
.leadership-item__name {
  color: #4a4a4a;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.25; /* 30px */
  margin-bottom: 5px;
}
.leadership-item__position {
  color: #000;
  font-size: 20px; /* 20px */
  font-size: 1.25rem; /* 20px */
  line-height: 1.4; /* 28px */
}

.author {
  display: inline-block;
  margin-right: 25px;
}
.author__image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.author__info {
  display: inline-block;
  vertical-align: middle;
}

/* LIGHTBOX */
.white-popup {
  padding: 70px 55px;
  color: #000000;
  font-size: 20px;
  line-height: 30px;
}
.white-popup .leadership-item__name {
  color: #4a4a4a;
  font-size: 37px;
  line-height: 35px;
}
.white-popup .leadership-item__position {
  color: #000000;
  font-size: 22px;
  line-height: 31px;
}
.white-popup .leadership-item__second,
.white-popup .leadership-item__main {
  width: 300px;
  margin-bottom: 20px;
}
.white-popup .leadership-item__main {
  margin-top: 15px;
}
.white-popup .leadership-item__name {
  margin-bottom: 15px;
}
.white-popup .leadership-item__content {
  margin-top: 20px;
}
.white-popup .leadership-item__contact a {
  color: #000000;
  font-size: 20px;
  line-height: 35px;
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 30px;
}
.white-popup .leadership-item__mail:before {
  content: "";
  background-image: url(../img/mail.svg);
  background-image: contain;
  width: 20px;
  display: inline-block;
  height: 20px;
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
}
.white-popup .leadership-item__phone:before {
  content: "";
  background-image: url(../img/phone.svg);
  background-image: contain;
  width: 20px;
  display: inline-block;
  height: 20px;
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
}
.white-popup .leadership-item__linkedin {
  background-image: url(../img/linkedin.svg);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  text-indent: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.map-controls__title {
  color: rgb(46, 134, 193);
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.25; /* 30px */
  margin-bottom: 10px;
}
.map-controls .satellite-link {
  display: inline-block;
  font-size: 11px; /* 11px */
  font-size: 0.6875rem; /* 11px */
  line-height: 1.0909090909; /* 12px */
  color: rgb(46, 134, 193);
  padding: 0;
  padding-left: 10px;
  margin-bottom: 3px;
  margin-top: 4px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.map-controls .satellite-link:hover {
  color: rgb(41.0920502092, 119.7029288703, 172.4079497908);
}

.layer-toggle {
  color: #000000;
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 12px; /* 12px */
  font-size: 0.75rem; /* 12px */
  line-height: 2.1666666667; /* 26px */
  letter-spacing: 2px;
  transition: 0.2s linear;
  text-transform: uppercase;
  cursor: pointer;
}
.layer-toggle--is-active, .layer-toggle:hover {
  color: rgb(46, 134, 193);
}

.satelitte-svg-contain #overlays .overlay-layer {
  opacity: 0;
  transition: 0.3s linear;
}
.satelitte-svg-contain #overlays .overlay-layer--is-active {
  opacity: 1;
}

/* SATELLITE TEMPLATE */
.stat__tabs {
  padding-left: 0;
  margin-bottom: 50px;
}

.stat__tab-item {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 18px;
}

.active.stat__tab-item {
  background-color: #47bcc9;
  color: white;
  padding: 7px 16px;
  cursor: initial;
}

.stat__img {
  width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0%;
}

.new-loader {
  display: block;
  width: 250px;
  margin: 20px auto;
  text-align: center;
}

.satelitte-svg-contain svg {
  width: 100%;
  height: 580px;
}

@media screen and (min-width: 750px) and (max-width: 900px) {
  .satelitte-svg-contain svg {
    height: 430px;
  }
}
@media screen and (min-width: 600px) and (max-width: 749px) {
  .satelitte-svg-contain svg {
    height: 330px;
  }
}
@media screen and (min-width: 450px) and (max-width: 599px) {
  .satelitte-svg-contain svg {
    height: 230px;
  }
}
@media screen and (max-width: 449px) {
  .satelitte-svg-contain svg {
    height: 180px;
  }
}
.tweet {
  margin: 0 0 15px;
  color: white;
  max-width: 570px;
  font-size: 24px; /* 24px */
  font-size: 1.5rem; /* 24px */
  line-height: 1.3333333333; /* 32px */
}
.tweet a {
  color: rgb(42, 162, 239);
}

.tweet-time {
  color: white;
  font-size: 14px;
}
.tweet-time img {
  margin-right: 10px;
}

.social-links {
  font-family: "AvantGardeGothicITCW02Md", serif;
  font-size: 12px; /* 12px */
  font-size: 0.75rem; /* 12px */
  line-height: 1.25; /* 15px */
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.social-links img {
  margin-right: 12px;
  height: 48px;
  width: 48px;
}
.social-links span {
  margin-right: 20px;
}
.social-links .row > div {
  margin-top: 8px;
}

.connect-title {
  font-size: 28px; /* 28px */
  font-size: 1.75rem; /* 28px */
  line-height: 1.4285714286; /* 40px */
}

.off-screen {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.6s ease-in-out;
}
.off-screen.off-screen--children-in-right > div {
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}
.off-screen.off-screen--children-in-right > div:nth-child(2) {
  transition-delay: 0.3s;
}
.off-screen.off-screen--children-in-right > div:nth-child(3) {
  transition-delay: 0.6s;
}
.off-screen.off-screen--children-in-right > div:nth-child(4) {
  transition-delay: 0.9s;
}
.off-screen.off-screen--q-and-a .speech-bubble {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__1 {
  transition: opacity 0.4s ease-in-out 0.2s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__2 {
  transition: opacity 0.4s ease-in-out 0.4s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__3 {
  transition: opacity 0.4s ease-in-out 0.6s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__4 {
  transition: opacity 0.4s ease-in-out 0.8s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__5 {
  transition: opacity 0.4s ease-in-out 1s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--q-and-a .speech-bubble.off-screen__6 {
  transition: opacity 0.4s ease-in-out 1.2s, transform 0.4s ease-in-out;
}
.off-screen.off-screen--children-fade > * {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.off-screen.off-screen--children-fade > *:nth-child(2) {
  transition-delay: 0.2s;
}
.off-screen.off-screen--children-fade > *:nth-child(3) {
  transition-delay: 0.4s;
}
.off-screen.off-screen--children-fade > *:nth-child(4) {
  transition-delay: 0.6s;
}
.off-screen.off-screen--children-fade > *:nth-child(5) {
  transition-delay: 0.8s;
}
.off-screen.off-screen--children-fade > *:nth-child(6) {
  transition-delay: 1s;
}
.off-screen.off-screen--children-fade > *:nth-child(7) {
  transition-delay: 1.2s;
}
.off-screen.off-screen--children-fade > *:nth-child(8) {
  transition-delay: 1.4s;
}
.off-screen.off-screen--children-fade > *:nth-child(9) {
  transition-delay: 1.6s;
}
.off-screen.off-screen--children-fade > *:nth-child(10) {
  transition-delay: 1.8s;
}
.off-screen.off-screen--children-fade > *:nth-child(11) {
  transition-delay: 2s;
}

.js .off-screen--hide.wrapper-dropdown {
  width: 40%;
}
.js .off-screen--hide.off-screen--from-right {
  transform: translateX(250px);
  opacity: 0;
}
.js .off-screen--hide.off-screen--from-left {
  transform: translateX(-250px);
  opacity: 0;
}
.js .off-screen--hide.off-screen--from-bottom {
  transform: translateY(200px);
  opacity: 0;
}
.js .off-screen--hide.off-screen--children-in-right > div {
  transform: translateX(250px);
  opacity: 0;
}
.js .off-screen--hide.off-screen--children-in-bottom > div {
  transform: translateY(200px);
  opacity: 0;
}
.js .off-screen--hide.off-screen--children-fade > * {
  opacity: 0;
}
.js .off-screen--hide.off-screen--fade {
  opacity: 0;
}
.js .off-screen--hide.off-screen--q-and-a .speech-bubble {
  opacity: 0;
}

.alternating-logos img {
  padding: 0 30px;
  display: block;
  max-width: 100%;
  max-height: 60px;
  margin: auto;
}

.random-logo {
  background: #EEF2F5;
  padding: 30px 0;
  margin-bottom: 50px;
}
.random-logo h2 {
  margin-bottom: 30px;
}
.random-logo img {
  margin-bottom: 20px;
}

.map-contain {
  height: 0;
  padding-bottom: 60%;
}

.newsletter-popup {
  position: fixed;
  right: 0;
  width: 420px;
  max-width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
  background: black;
  z-index: 9999;
  transition: transform 0.4s ease-in-out;
}
.newsletter-popup--is-hidden {
  transform: translate(100%, -50%);
}
.newsletter-popup .close-popup {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.newsletter-popup .close-popup:before, .newsletter-popup .close-popup:after {
  content: "";
  position: absolute;
  top: 12px;
  background: white;
  width: 25px;
  height: 2px;
}
.newsletter-popup .close-popup:before {
  transform: rotate(45deg);
}
.newsletter-popup .close-popup:after {
  transform: rotate(-45deg);
}
.newsletter-popup__image {
  width: 100%;
  height: 0;
  padding-bottom: 46%;
  background-size: cover;
  background-position: center;
  position: relative;
}
.newsletter-popup__content {
  padding: 35px 35px 10px;
}
.newsletter-popup__title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.newsletter-popup__intro {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 25px;
  margin-top: 12px;
}
.newsletter-popup__privacy {
  margin-top: 10px;
}
.newsletter-popup__privacy p {
  font-size: 14px;
  line-height: 22px;
}
.newsletter-popup label {
  display: none;
}
.newsletter-popup .mc-field-group {
  width: calc(100% - 130px);
  float: left;
  margin-right: 4px;
}
.newsletter-popup .mc-field-group input {
  border: none;
  padding: 11px 15px;
}
.newsletter-popup input[type=submit] {
  padding: 12px 15px;
}

@media screen and (max-width: 600px) {
  .newsletter-popup {
    top: 0;
    transform: translate(0, 0);
  }
  .newsletter-popup--is-hidden {
    transform: translate(100%, 0);
  }
  .newsletter-popup__content {
    padding: 25px 25px 10px;
  }
}
.newsletter-container {
  margin-top: 5px;
}
.newsletter-container input[type=email] {
  font-size: 14px;
  padding: 22px 20px;
}
.newsletter-container p {
  margin: 30px 0 40px 0;
}

.icons {
  margin-bottom: 70px;
  position: relative;
}
.icons .icon {
  height: 100%;
  padding: 5rem 4rem;
  transition: all 0.3s ease-in-out;
}
.icons .icon h3 {
  color: white;
  margin: 0;
  padding: 0;
  padding-right: 1rem;
}
.icons .icon img {
  height: 5rem;
  padding-right: 20px;
}
.icons .icon_teal {
  background: #2e86c1;
}
.icons .icon_teal:hover {
  background: #3f99a3;
  transition: all 0.3s ease-in-out;
}
.icons .icon_purple {
  background: #7c77a6;
}
.icons .icon_purple:hover {
  background: #625e82;
  transition: all 0.3s ease-in-out;
}
.icons .icon_green {
  background: #76bf7d;
}
.icons .icon_green:hover {
  background: #64a16a;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 990px) {
  .icons__icon-container {
    margin-bottom: 1rem;
  }
}
.news-slider .slick-arrow {
  visibility: hidden;
}
.news-slider .slick-dots {
  bottom: 67px;
  left: -15%;
}
.news-slider .slider-overlay {
  max-height: none !important;
}
.news-slider .slider-overlay__content__inner {
  padding: 3rem;
  font-family: "AvantGardeGothicITCW01Bk", sans-serif !important;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 520px;
}
.news-slider .slider-overlay__content__inner .info {
  font-size: 13px !important;
  text-transform: uppercase;
}
.news-slider .slider-overlay__content__inner .info .seperator {
  padding: 0 1rem;
}
.news-slider .slider-overlay__content__inner .info .date {
  opacity: 0.5;
}
.news-slider .slider-overlay__content__inner h3 {
  margin-bottom: 1rem;
}
.news-slider .slider-overlay__content__inner .excerpt {
  margin-bottom: 2rem;
  white-space: normal;
}
.news-slider .slider-overlay__content__inner .button {
  width: fit-content;
  width: -moz-fit-content;
}
.news-slider .slider-overlay__overlay {
  opacity: 0.84;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .news-slider .slick-arrow {
    visibility: hidden;
  }
  .news-slider .slick-dots {
    bottom: 67px;
    left: 0;
    margin-left: 4rem;
    text-align: left;
  }
  .news-slider .slider-overlay {
    max-height: none !important;
  }
  .news-slider .slider-overlay__content__inner {
    padding: 3rem;
    font-family: "AvantGardeGothicITCW01Bk", sans-serif !important;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    height: 520px;
  }
}
.services-wheel {
  padding: 60px 0;
}
@media screen and (max-width: 992px) {
  .services-wheel {
    padding: 30px 15px;
  }
}
.services-wheel .slide a {
  color: inherit;
}
.services-wheel .slide p {
  color: black;
  font-size: 22px;
  line-height: 34px;
  font-family: "AvantGardeGothicITCW01Bk", sans-serif;
}
.services-wheel .main-title h2 {
  font-size: 72px !important;
  margin-bottom: 60px;
}
.services-wheel__slider .slide .body {
  color: black;
}
.services-wheel__slider .slide ul {
  margin-top: 30px;
}
@media screen and (min-width: 540px) {
  .services-wheel__slider .slide ul {
    column-count: 2;
    column-gap: 3em;
  }
}
.services-wheel__slider .slide ul li::marker {
  content: "— ";
  font-size: 1.2em;
}
.services-wheel .service-title {
  letter-spacing: -1px;
  font-size: 40px;
  line-height: 1.2;
}
.services-wheel .wheel {
  position: relative;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .services-wheel .wheel {
    display: none;
  }
}
.services-wheel .wheel svg {
  width: calc(100% + 50vw - 680px);
}
@media screen and (max-width: 1340px) {
  .services-wheel .wheel svg {
    width: calc(100% + 50vw - 580px);
  }
}
@media screen and (max-width: 1200px) {
  .services-wheel .wheel svg {
    width: calc(100% + 50vw - 500px);
  }
}
@media screen and (max-width: 992px) {
  .services-wheel .wheel svg {
    width: calc(100% + 50vw - 360px);
  }
}
.services-wheel .wheel tspan {
  letter-spacing: 1.65px;
  font-size: 14px;
  line-height: 14px;
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.services-wheel .wheel__icons {
  position: absolute;
  left: 34vw;
  top: 47%;
  width: 140px;
  transform: translate(-50%, -50%);
}
.services-wheel .wheel__icons svg {
  width: 140px;
}
.services-wheel .services-wheel-nav {
  position: relative;
  display: inline-block;
  padding-top: 1rem;
}
.services-wheel .services-wheel-nav .wheel-arrow-next,
.services-wheel .services-wheel-nav .wheel-arrow-prev {
  color: black;
  font-size: 2rem;
  border: none;
  position: relative;
  inset: auto;
  display: contents;
}
.services-wheel .services-wheel-nav .count {
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px !important;
  letter-spacing: 1.4px;
}
.services-wheel li {
  text-transform: uppercase;
  letter-spacing: 1.65px;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: "AvantGardeGothicITCW02Md", serif;
}
.services-wheel [data-js=change-services-slider] {
  cursor: pointer;
}
.services-wheel .circle-control {
  opacity: 0.5;
}
.services-wheel .main-circle {
  opacity: 0;
  transition: 0.3s;
}
.services-wheel .circle-active .circle-control {
  opacity: 1;
  transform: scale(1);
}
.services-wheel .circle-active .main-circle {
  opacity: 1;
}
.services-wheel-new .circle-active path[fill="#CCCCCC"] {
  transition: 0.3s all ease-in;
  fill: #2E86C1;
}

@media screen and (max-width: 1366px) {
  .slider-overlay--full .slide__title {
    font-size: 78px; /* 78px */
    font-size: 4.875rem; /* 78px */
    line-height: 1.0256410256; /* 80px */
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 15px;
  }
  .col-7__col {
    width: 24%;
    margin-top: 30px;
  }
  .sub-title {
    font-size: 24px;
  }
  .slider-overlay--arrows .slick-prev {
    left: 20px;
  }
  .slider-overlay--arrows .slick-next {
    right: 20px;
  }
  .slider-overlay--arrows.quotes-slider .col-md-6 {
    margin-left: 40px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (min-width: 925px) and (max-width: 1100px) {
  .box {
    padding: 40px;
  }
  .box__bottom {
    bottom: 40px;
    left: 40px;
    right: 40px;
  }
  .box__title {
    font-size: 35px;
  }
  .box p {
    font-size: 22px;
  }
  .stat__number {
    font-size: 125px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .slider-overlay--full .slide__title {
    font-size: 65px; /* 65px */
    font-size: 4.0625rem; /* 65px */
    line-height: 1.0769230769; /* 70px */
  }
  .slide p {
    font-size: 20px; /* 20px */
    font-size: 1.25rem; /* 20px */
    line-height: 1.3; /* 26px */
  }
  .site-footer .logo-social-search {
    margin-top: 40px;
  }
  .site-footer .footer-logo-wrapper {
    float: left;
  }
  .site-footer .social-media-links {
    float: left;
    margin-left: 40px;
  }
  .site-footer .site-searchbar {
    margin-top: 24px;
  }
  .site-footer input[type=search] {
    width: 220px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .box__title__socials {
    margin-bottom: 20px !important;
  }
  .white-popup {
    padding: 20px;
  }
  .central-question {
    display: none;
  }
  .overlay__center .central-question {
    display: block;
  }
  .central-question--mobile {
    display: block;
  }
  .q-and-a .speech-bubble {
    width: calc(50% - 20px);
    display: inline-block;
    margin: 20px 10px !important;
    vertical-align: middle;
  }
  .top-nav__logo img {
    width: 170px;
    margin-top: -40px;
    margin-left: -40px;
  }
  .image-and-stat h2 {
    font-size: 30px;
  }
  .image-and-stat img {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .page-template-tpl-satellite .stat__img {
    background-position: center;
  }
  section.image-and-stat {
    margin-bottom: 25px;
  }
  .q-and-a__overlay {
    height: auto;
    width: 85%;
  }
  .page-template-tpl-get-in-touch .contact-details-wrapper {
    text-align: center;
    margin-bottom: 30px;
  }
  .box__meta .date {
    opacity: 1;
  }
  .teaser {
    padding-bottom: 50%;
  }
  .leadership-item__name {
    font-size: 28px; /* 28px */
    font-size: 1.75rem; /* 28px */
    line-height: 1.0714285714; /* 30px */
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 924px) {
  .box__video-contain {
    display: none;
  }
  .box {
    padding: 25px;
  }
  .masonary__item {
    width: calc(50% - 10px);
  }
  .masonary__item--large {
    width: calc(100% - 10px);
  }
  .masonary__item--full-width {
    width: 100%;
  }
  .masonary__item.blog-item {
    width: calc(100% - 10px);
  }
  .floating-header {
    transform: none;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .sub-title {
    font-size: 24px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 769px) {
  html {
    font-size: 15px;
  }
  .floating-pattern .col-sm-7 {
    width: 100%;
  }
  .col-7__col {
    width: 24%;
    margin-top: 30px;
  }
  .stat__number {
    font-size: 100px;
  }
  .slider-overlay--full .slick-dots {
    bottom: 18px;
    right: -30px;
  }
  .slider-overlay--arrows.quotes-slider .col-sm-6 {
    width: 100%;
    padding: 0 50px;
  }
  .box p {
    font-size: 21px; /* 21px */
    font-size: 1.3125rem; /* 21px */
    line-height: 1.380952381; /* 29px */
  }
  .video-popup .box__bottom--lower.text-right {
    text-align: center;
    bottom: 50%;
    margin-bottom: -30px;
  }
  .video-popup .box__bottom .box__title {
    margin-bottom: 0;
    text-align: center;
  }
  .selection-list {
    font-size: 28px; /* 28px */
    font-size: 1.75rem; /* 28px */
    line-height: 1.2857142857; /* 36px */
  }
  .slider-overlay__overlay {
    transform: scaleX(1);
  }
  .slider-overlay__overlay--off-left {
    transform: scaleX(1) translateX(-100%);
  }
  .masonary__item--large .box__title {
    font-size: 33px; /* 33px */
    font-size: 2.0625rem; /* 33px */
    line-height: 1.1515151515; /* 38px */
  }
  .title {
    font-size: 55px; /* 55px */
    font-size: 3.4375rem; /* 55px */
    line-height: 1.1818181818; /* 65px */
  }
  .intro {
    font-size: 23px; /* 23px */
    font-size: 1.4375rem; /* 23px */
    line-height: 1.2173913043; /* 28px */
  }
  .tweet {
    font-size: 18px; /* 18px */
    font-size: 1.125rem; /* 18px */
    line-height: 1.3333333333; /* 24px */
  }
  .box__bottom {
    left: 25px;
    right: 25px;
  }
  .social-links a {
    display: block;
    margin-bottom: 4px;
  }
  .social-links img {
    width: 30px;
  }
  .floating-pattern .floating-pattern__pattern {
    display: none;
  }
  .floating-pattern .title,
  .floating-pattern .intro {
    text-align: center;
  }
  .alternating-logos .col-7__col {
    width: auto;
    padding: 10px 3px;
  }
  .banner__overlay {
    transform: scaleX(1);
  }
  .speech-bubble {
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .stat .stat__icon {
    float: none;
  }
  .keyfacts-row-2 > div:nth-child(2) {
    margin-top: 80px;
  }
  .mfp-wrap {
    z-index: 99999;
  }
  .mfp-container .white-poup .text-center img {
    width: 300px;
    float: right;
    padding-left: 30px;
    padding-bottom: 20px;
  }
  .video-strip {
    margin-bottom: 25px;
    margin-top: -20px;
  }
  h2 {
    font-size: 44px;
    line-height: 1.08;
  }
  .slider-overlay__down {
    left: 20px;
  }
  .site-footer {
    text-align: center;
  }
  .site-footer ul {
    text-align: left;
    margin-bottom: 30px;
  }
  .site-footer ul li {
    text-align: center;
  }
  .site-footer .logo-social-search,
  .site-footer .footer-logo-wrapper {
    text-align: center;
  }
  .copyright {
    border-top: none;
  }
  .page-template-tpl-whats-new .masonary__item,
  .archive .masonary__item {
    height: 320px;
  }
  .page-template-tpl-whats-new .masonary__item .box,
  .archive .masonary__item .box {
    padding: 30px;
  }
  .page-template-tpl-whats-new .masonary__item .box__title,
  .archive .masonary__item .box__title {
    font-size: 20px;
  }
  .page-template-tpl-whats-new .masonary__item .box__content,
  .archive .masonary__item .box__content {
    max-height: 120px;
    overflow: hidden;
  }
  .map-controls {
    margin-bottom: 30px;
    margin-top: -30px;
  }
  .map-controls .col-md-4 {
    text-align: center;
  }
  .q-and-a__overlay .col-sm-8 {
    padding: 0 30px;
  }
  .q-and-a__overlay .answer {
    font-size: 32px;
  }
  .q-and-a .demos img {
    height: 50px;
  }
  .q-and-a .central-question {
    margin: 65px 0;
  }
  .q-and-a__overlay-close {
    top: 10px;
    right: 30px;
  }
  .teaser {
    padding-bottom: 40%;
  }
  .site-footer .footer-logo-wrapper {
    margin-top: 30px;
  }
  .site-footer .copyright {
    margin-top: 20px;
  }
  .site-footer .copyright .text-right {
    text-align: center !important;
    margin-top: 30px;
  }
  .slider-overlay--full {
    height: 90vh;
  }
  .banner {
    padding-bottom: 0;
    min-height: 400px;
  }
  .banner.object-fit img {
    min-height: 400px;
  }
  .banner:not(.object-fit) .banner__center-text {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    padding-top: 150px;
    padding-bottom: 90px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .q-and-a .speech-bubble {
    width: 100%;
    margin: 20px auto !important;
  }
  .mix-locations .mix {
    width: 100%;
    margin-bottom: 30px;
  }
  .location-teaser__content {
    height: auto;
  }
  .q-and-a__overlay {
    height: auto;
    width: 95%;
  }
  .q-and-a__overlay .col-sm-8 {
    padding: 0 20px;
  }
  .q-and-a .speech-bubble:hover {
    transform: scale(1.08);
  }
  .floating-header {
    text-align: center;
  }
  .floating-header .text-right {
    text-align: center;
    margin-top: 20px;
  }
  .slider-overlay--arrows.quotes-slider {
    color: black;
  }
  .slider-overlay--arrows.quotes-slider .slide__bg {
    visibility: hidden;
  }
  .slider-overlay--arrows.quotes-slider .slider-overlay__overlay {
    visibility: hidden;
  }
  .slider-overlay--arrows.quotes-slider .slide__title,
  .slider-overlay--arrows.quotes-slider p {
    text-align: center;
    color: black;
  }
  .slider-overlay--arrows.quotes-slider .slick-dots li button {
    background: #c9c9c9;
  }
  .slider-overlay--arrows.quotes-slider .slick-dots li.slick-active button {
    background: rgb(40, 116, 166);
  }
  .slider-overlay--arrows.quotes-slider .slick-dots li button::before {
    border: 1px solid #2E86C1;
  }
  .slider-overlay--arrows.quotes-slider .slick-prev {
    left: 10px;
  }
  .slider-overlay--arrows.quotes-slider .slick-next {
    right: 10px;
  }
  .slider-overlay--arrows.quotes-slider .slide__title {
    font-size: 42px; /* 42px */
    font-size: 2.625rem; /* 42px */
    line-height: 1.0952380952; /* 46px */
  }
  .slider-overlay--arrows.quotes-slider .col-md-6 {
    padding: 0 30px;
  }
  .slider-overlay--full .slide__title {
    font-size: 46px; /* 46px */
    font-size: 2.875rem; /* 46px */
    line-height: 1.1304347826; /* 52px */
  }
  .slide p {
    font-size: 21px; /* 21px */
    font-size: 1.3125rem; /* 21px */
    line-height: 1.380952381; /* 29px */
  }
  .slider-overlay--arrows.quotes-slider .slide__title {
    font-size: 32px; /* 32px */
    font-size: 2rem; /* 32px */
    line-height: 1.09375; /* 35px */
    margin-bottom: 15px;
  }
  .slider-overlay--arrows.quotes-slider p {
    font-size: 18px; /* 18px */
    font-size: 1.125rem; /* 18px */
    line-height: 1.2777777778; /* 23px */
  }
  .slider-overlay--arrows.quotes-slider p strong {
    font-weight: 400;
    color: rgb(46, 134, 193);
    font-size: 18px; /* 18px */
    font-size: 1.125rem; /* 18px */
    line-height: 1.3888888889; /* 25px */
  }
  .leadership-item__contact a {
    font-size: 15px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
  .masonary__item .box__bottom {
    text-align: center !important;
  }
}
@media screen and (max-width: 680px) {
  .masonary__item {
    width: calc(100% - 10px);
  }
  .box--bg-mobile-full {
    background: rgba(0, 0, 0, 0.6);
  }
  .copyright span {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  .accordion__heading {
    font-size: 1.4rem;
  }
  .accordion__heading:after {
    top: 13px;
  }
  .accordion__heading:before {
    top: 19px;
  }
  .masonary__item--full-width {
    width: 100%;
  }
  .masonary__item .box__bottom {
    text-align: center !important;
  }
}
@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
  .col-7__col {
    width: 34%;
    margin-top: 30px;
  }
  .q-and-a__left-col,
  .q-and-a__center-col,
  .q-and-a__right-col {
    text-align: center;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .teaser {
    padding-bottom: 55%;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  html {
    font-size: 13px;
  }
  .col-7__col {
    width: 100%;
    margin-top: 15px;
  }
  .help-footer .col-7__col {
    width: 50%;
  }
  .leadership-item-wrapper {
    width: 100%;
  }
  .leadership-item-wrapper.col-xs-offset-3 {
    margin-left: 0;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
@media screen and (max-width: 385px) {
  .office-stat-strip {
    text-align: center;
  }
  .office-stat-strip .stat {
    width: 100%;
    margin: 20px auto;
  }
  .office-stat-strip .span {
    display: block;
    width: 100%;
    margin: 20px auto;
  }
  .masonary__item--full-width {
    width: 100%;
  }
}
.masonary__item--full-width {
  width: 100% !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px;
}

@media screen and (max-width: 1025px) {
  .menu-main-container,
  .menu-main-french-container {
    position: fixed;
    background: black;
    height: 100vh;
    top: 0;
    width: 100vw;
    right: 0;
    transform: translateX(100%);
    transition: 0.4s ease;
  }
  .menu-main-container li,
  .menu-main-french-container li {
    display: block;
    text-align: left;
  }
  .menu-main-container.is-active,
  .menu-main-french-container.is-active {
    transform: translateX(0);
  }
  .top-nav ul.menu {
    position: initial;
    width: 100%;
    padding: 66px;
  }
  .top-nav ul.menu .sub-menu {
    position: fixed;
    background: #333;
    height: 100vh;
    top: 0;
    width: 450px;
    max-width: 95vw;
    right: 0;
    z-index: 999;
    transform: translateX(100%);
    transition: 0.4s ease;
    padding: 66px;
  }
  .top-nav ul.menu .sub-menu.is-active {
    opacity: 1;
    transform: translateX(0);
  }
  .top-nav ul.menu .sub-menu .back {
    color: white;
    font-size: 24px;
  }
  .top-nav ul.menu li {
    padding: 0;
  }
  .top-nav ul.menu li .sub-menu li {
    padding: 0;
  }
  .hamburger-mobile {
    display: block;
    height: 70px;
    width: 50px;
    background: #191919;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  .hamburger {
    padding: 26px 16px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  .hamburger-box {
    width: 18px;
    height: 15px;
    display: inline-block;
    position: relative;
  }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 18px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -6px;
  }
  .hamburger-inner::after {
    bottom: -6px;
  }
  .hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .menu-main-container {
    overflow: hidden;
  }
  .top-nav ul.menu {
    margin-top: 60px;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
  .top-nav ul.menu::after {
    height: 530px;
    width: 262px;
    right: 0px;
    bottom: 0;
    background: url(../img/menu_bg_element.svg);
    background: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .menu-main-container li {
    padding-bottom: 20px;
  }
  .top-nav ul.menu .sub-menu .back {
    position: absolute;
    top: 50px;
    left: 70px;
    text-indent: -9999px;
    color: black;
    width: 16px;
    height: 30px;
    background-image: url("../img/icon-blue-menu-icon.svg");
    background-size: 15px 10px;
    transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .top-nav li a {
    line-height: 1.3;
  }
  .top-nav .sub-menu li {
    padding: 0;
    margin-bottom: 8px;
  }
  .top-nav .sub-menu li:first-child {
    margin-top: 40px;
  }
  .top-nav .language-switcher {
    top: 0;
    position: fixed;
    right: 60px;
  }
  #menu-item-26 > ul > .menu-item,
  #menu-item-3361 > ul > .menu-item {
    background-color: none;
  }
  #menu-item-26 > ul > .menu-item > a,
  #menu-item-3361 > ul > .menu-item > a {
    color: #2e86c1;
    padding-left: 0px;
    text-transform: none;
    font-size: 20px;
    letter-spacing: -0.73px;
  }
  #menu-item-26 > ul > .menu-item > .sub-menu,
  #menu-item-3361 > ul > .menu-item > .sub-menu {
    position: relative;
    top: 0;
    bottom: 0;
    width: auto;
    height: fit-content;
    max-width: none;
    right: 0;
    z-index: 999;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    padding: 0px;
    background: none;
    padding-bottom: 1rem;
  }
  #menu-item-26 > ul > .menu-item > .sub-menu:before,
  #menu-item-3361 > ul > .menu-item > .sub-menu:before {
    background: none !important;
  }
  #menu-item-26 > ul > .menu-item > .sub-menu:after,
  #menu-item-3361 > ul > .menu-item > .sub-menu:after {
    background: none !important;
    position: unset;
    content: none;
    height: 462px;
    width: 471px;
  }
  #menu-item-26 > ul > .menu-item > .sub-menu .back,
  #menu-item-3361 > ul > .menu-item > .sub-menu .back {
    background-image: none;
  }
  #menu-item-26 > ul > .menu-item > .sub-menu > li,
  #menu-item-3361 > ul > .menu-item > .sub-menu > li {
    margin-top: 0;
    background: none !important;
    background-color: none;
    padding: 0;
  }
  #menu-item-26 > .sub-menu,
  #menu-item-3361 > .sub-menu {
    padding-top: 6rem;
    overflow: scroll;
    background: black;
    overflow-x: hidden;
  }
  #menu-item-26 > .sub-menu:after,
  #menu-item-3361 > .sub-menu:after {
    height: 245px;
    width: 471px;
    bottom: -471px;
  }
  #menu-item-26 .menu-item-9486,
  #menu-item-3361 .menu-item-9486 {
    margin-top: 40px;
  }
  #menu-item-26 .menu-item-9491,
  #menu-item-3361 .menu-item-9491 {
    margin-bottom: 10rem;
  }
}
@media screen and (min-width: 1024px) {
  #menu-item-26 > .sub-menu,
  #menu-item-3361 > .sub-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 72px;
    grid-row-gap: 24px;
    padding: 72px;
    width: 80vw;
    transition: all 0.4s ease-in-out;
  }
  #menu-item-26 > .sub-menu:after,
  #menu-item-3361 > .sub-menu:after {
    height: 50%;
    left: 0;
    bottom: 0;
    transform: rotate(0);
  }
  #menu-item-26 .sub-menu > li > a,
  #menu-item-3361 .sub-menu > li > a {
    color: #2e86c1;
    font-size: 22px !important;
    text-transform: initial;
    letter-spacing: -0.73px;
    transition: all 0.4s ease-in-out;
  }
  #menu-item-26 .sub-menu > li > .sub-menu > li > a,
  #menu-item-3361 .sub-menu > li > .sub-menu > li > a {
    color: #ffffff;
    font-size: 14px !important;
    text-transform: uppercase;
    letter-spacing: 1.65px;
    transition: all 0.4s ease-in-out;
  }
  #menu-item-26 .sub-menu > li > .sub-menu > li > a:hover,
  #menu-item-3361 .sub-menu > li > .sub-menu > li > a:hover {
    color: #2e86c1;
  }
  #menu-item-26 .sub-menu > li > .sub-menu:after,
  #menu-item-3361 .sub-menu > li > .sub-menu:after {
    background: none;
  }
  #menu-item-26 .sub-menu > li > .sub-menu,
  #menu-item-3361 .sub-menu > li > .sub-menu {
    position: relative;
    padding: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }
  #menu-item-23 > .sub-menu:after {
    right: auto;
    left: 0;
    transform: rotate(0);
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  background: rgb(40, 116, 166);
  color: black;
  padding: 10px;
}

::selection {
  background: rgb(40, 116, 166);
  color: black;
  padding: 10px;
}

.masonary__item .box--black::selection,
.masonary__item .box--black::-moz-selection {
  color: rgb(46, 134, 193) !important;
}

#content-wrap {
  overflow: hidden;
}

.floating-pattern {
  position: relative;
  overflow: visible;
}
.floating-pattern .floating-pattern__pattern {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transform: rotate(45deg);
}
.floating-pattern .floating-pattern__pattern img {
  width: 550px;
}

.page-template-tpl-key-facts .slider-overlay .stat__description {
  margin-left: 16px;
}

.single .comments-section {
  margin-top: 40px;
  border-top: 1px solid #ccc;
}
.single .comments-section h2 {
  font-size: 36px;
}
.single .comments-section #commentform div {
  margin-bottom: 25px;
}
.single .comments-section input[type=text] {
  border: 1px solid black;
}
.single .comments-section textarea {
  border: 1px solid black;
}
.single .comments-section input[type=submit],
.single .comments-section input[type=reset],
.single .comments-section input[type=button] {
  text-align: center;
  display: inline-block;
  padding: 1.4375rem;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: solid 2px;
  transition: all 0.3s linear;
  border: none;
  color: white;
  font-size: 13px; /* 13px */
  font-size: 0.8125rem; /* 13px */
  line-height: 1.3846153846; /* 18px */
  letter-spacing: 2px;
  background: rgb(40, 116, 166);
  text-transform: uppercase;
}
.single .comments-section input[type=submit]:hover,
.single .comments-section input[type=reset]:hover,
.single .comments-section input[type=button]:hover {
  background: rgb(36.1841004184, 105.4058577406, 151.8158995816);
}

@media (min-width: 1340px) {
  .container--masonary {
    width: 1340px;
    max-width: 100%;
  }
  .container--wider {
    width: 1340px;
    max-width: 100%;
  }
}